
.our {
  position: relative;
  padding: 140px 50px;
  align-items: center;
  overflow: hidden;
  &>div{
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
    & > .text {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 6px;
      padding: 50px 300px 50px 50px;
      box-sizing: border-box;
      margin: 0 auto;
      width: 100%;
    }
    .image {
      position: absolute;
      top: 50%;
      right: -50px;
      height: 673px;
      min-width: 340px;
      &[data-aos^=fade][data-aos^=fade].aos-animate{
        transform: translateY(-50%);
      }
    }
  }
  .title {
    text-align: left;
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 15px;
  }
  .subtitle {
    position: relative;
    font-size: 18px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &:before {
      content: '';
      width: 200px;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .speciality {
    margin-bottom: 20px;
    span {
      display: inline-block;
      border-radius: 15px;
      font-size: small;
      color: white;
      padding: 2px 15px;
      margin: 0 0 5px 5px;
    }
  }
  .item {
    margin-bottom: 20px;
    .main {
      font-size: 16px;
      font-weight: 800;
    }
    .text {

    }
  }
}


// Mobile
@media (max-width: 900px) {
  .our {
    padding: 20px;
    &>div {
      width: 100%;
      .title {
        font-size: 25px;
      }
      .image {
        display: none;
      }
      & > .text {
        padding: 30px;
        width: 100%;
        max-width: 100%;;
      }
    }
  }
}

@media (min-width: 901px) and (max-width: 1250px){
  .our {
    overflow: hidden;
  }
}
