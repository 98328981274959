
#data {
  position: relative;
  margin-top: 245px;
  background-color: #F8F8F8;
  .wrap {
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .list {
    display: flex;
    justify-content: center;
    position: relative;
    top: -76px;

    .item {
      width: 33%;
      position: relative;
      margin: 10px;
      padding: 0px 50px 0 50px;

      .icon {
        width: 130px;
        height: 130px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 2rem;
        border: 6px solid white;
        box-sizing: border-box;

        &:before {
          font-size: 58px;
        }
      }
      .title {
        font-size: 28px;
        font-weight: 800;
        margin-bottom: 20px;
      }
      .description {
        box-sizing: border-box;
        font-size: 20px;
        border-radius: 4px;
      }
    }
  }
}


// Mobile
@media (max-width: 900px) {
  #data {
    margin-top: 0;
    .wrap {
      padding: 30px;
      .list {
        top: auto;
        flex-direction: column;
        .item {
          position: relative;
          width: auto;
          margin: 40px 0;
          .title {
            margin-bottom: 0;
            padding-left: 140px;
            font-size: 22px;
            font-weight: 800;;
          }
          .description {
            padding-left: 140px;
            font-size: 16px;
          }
          .icon {
            position: absolute;
            top: -22px;
            width: 110px;
            height: 110px;
            &:before {
              font-size: 38px !important;
            }
          }
        }
      }
    }
  }
}






// Small Mobile
@media (max-width: 700px) {
  #data {
    margin-top: 0;
    .wrap {
      padding: 20px;
      .list {
        .item {
          padding: 0;
          margin: 20px 0;
          .icon {
            width: 80px;
            height: 80px;
            top: -14px;
          }
          .title {
            padding-bottom: 10px;
            padding-left: 100px;
          }
          .description {
            padding-left: 100px;
          }
        }
      }
    }
  }
}
