
#floating {
  position: fixed;
  bottom: 0;
  right: 0px;
  margin: 20px;
  color: white;
  text-transform: capitalize !important;
  z-index: 50;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  border-radius: 4px;
  cursor: pointer;
  .text {
    padding-left: 5px;
    font-size: 16px;
    font-weight: 700;
  }
}

// Mobile
@media (max-width: 990px) {
  #floating {
    padding: 20px;
    border-radius: 50%;
    .text {
      display: none;
    }
    .icon-email:before {
      font-size: 20px;
      padding:0;
    }
  }
}


