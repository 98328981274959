
#success {
  padding: 40px 0 70px 0;
  h2 {
    text-align: center;
  }
  .images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-video {
      border-radius: 5px;
      position: relative;
      width: 100%;
      height: 300px;
      margin: 5px;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        .blend {
          background-color: rgba(0, 0, 0, 0.7) !important;
          background-blend-mode: overlay;
        }
        .play {
          cursor: pointer;
          transform: scale(1.5);
          opacity: 0.5;
        }
      }
      .play {
        position: absolute;
        width: 60px;
        height: 60px;
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transition: all 200ms;
      }
      .blend {
        background-color: rgba(0, 0, 0, 0.5) !important;
        background-blend-mode: overlay;
      }
    }
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;

  &.visible {
    display: block;
  }

  .video, video {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 700px;
    height: 400px;
    border: 1px solid white;
    margin: 0;
  }
  .shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  .close {
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 50;
    color: white;
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    background: black;
    text-align: center;
    border-radius: 50%;
    font-size: 22px;
    margin: 20px;
    padding: 10px 16px;
  }
}

// Mobile
@media (max-width: 800px) {
  #success {
    h2 {
      font-size: 25px;
    }
    .images-wrapper {
      flex-direction: column;
      .image-video {
        margin: 10px;
        width: 100%;
        height: calc(100vw * 0.5);
      }
    }
  }
}
@media (max-width: 1399px) {
  #success {
    .images-wrapper {
      padding: 0 20px;
    }
  }
}
