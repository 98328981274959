@import 'src/config/md/theme';
@import 'src/config/mv/theme';
.underline,.subtitle{
  &:before{
    background: var(--primary)
  }
}
#header {
  .menu-burger {
    .icon-burguer{
      color: var(--primary);
    }
    .aside-menu {
      background: var(--primary);
    }
  }
  .bottom-menu{
    & > div, & > a {
      &:hover{
        color: var(--primary);
      }
    }
  }
}
#features, #offer{
  i{
    color:var(--icons)
  }
}
.our{
  .paragraphs{
    .main{
      color: var(--primary);
    }
  }
  .speciality{
    span{
      background:var(--tags)
    }
  }
}
#milestone{
  .number{
    background: linear-gradient(202.61deg, var(--primaryGrafient) 0%, var(--secondaryGrafient) 100%);
  }
}
#testimony,#clients{
  .swiper-pagination-bullet{
    &.swiper-pagination-bullet-active{
      background: var(--primary);
    }
  }
}
#data{
  .icon{
    background: linear-gradient(202.61deg, var(--primaryGrafient) 0%, var(--secondaryGrafient) 100%);
  }
}
.contact{
  background-color: var(--quaternaryGrafient);
  background: linear-gradient(202.61deg, var(--tertiaryGrafient) 0%, var(--quaternaryGrafient) 100%);
  button{
    background: var(--secondary);
  }
}
#footer{
  .links,.links-map {
    a {
      &:hover{
        color: var(--primary);
      }
    }
  }
}
#floating{
  background: var(--primary);
}
