
#milestone {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5) !important;
  background-blend-mode: overlay;
  padding: 72px 0;

  h2 {
    text-align: left;
    color: white;

    &:before {
      right: auto;
    }
  }
  .wrap {
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
    display: block;
    box-sizing: border-box;
  }
  .list {
    display: flex;
    align-items: center;
    margin-top: 80px;

    .item {
      width: 25%;
      position: relative;
      margin: 10px;

      .number {
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 2rem;
        top: 0px;
        transform: translate(-50%, -50%);
        left: 50%;
        border: 6px solid white;
        font-weight: 900;
      }
      .description {
        box-sizing: border-box;
        padding: 80px 30px 40px 30px;
        height: 220px;
        background: white;
        font-size: 20px;
        border-radius: 4px;
        text-align: center;
      }
    }
  }
}


// Mobile
@media (max-width: 900px) {
  #milestone {
    padding: 30px;
    .wrap {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      width: auto;
      h2 {
        margin-left: 0;
        font-size: 25px;
      }
      .list {
        flex-direction: column;
        margin-top: 0;
        .item {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: auto;
          margin: 18px 0;
          margin-left: 25px;
          .number {
            width: 110px;
            height: 110px;
            position: absolute;
            top: -5px;
            left: -30px;
            transform: none;
            box-sizing: border-box;
            font-size: 23px;
          }
          .description {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            padding: 30px 20px 30px 100px;
            flex: 1;
            display: table;
            align-items: center;
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  #milestone {
    .wrap{
      padding: 0 40px;
    }
  }
}
@media (max-width: 700px) {
  #milestone {
    padding: 20px;
    .wrap{
      padding: 0 20px;
    }
  }
}
