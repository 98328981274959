.meeting-vets {
  --primary: #E52012;
  --secondary: #E52012;
  --primaryGrafient: #EB621A;
  --secondaryGrafient: #E52012;
  --tertiaryGrafient: #EB621A;
  --quaternaryGrafient: #FFA271;
  --icons: #EF7F43;
  --tags: #EF7F43;
}
