#features {
  color: #2C3E50;
  .features-container{
    display: flex;
    justify-content: center;
  }
  .item {
    padding: 32px;
    display: flex;
    align-items: center;
    flex: 1;
    .side {
      padding-left: 20px;
      .title {
        font-weight: bold;
      }
      .description {
        font-weight: 300;
      }
    }
  }
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-size: 36px;
  }
}

// Mobile
@media (max-width: 900px) {
  #features {
    padding: 20px;
    .features-container{
      flex-wrap: wrap;
    }
    [class^="icon-"]:before, [class*=" icon-"]:before {
      font-size: 24px;
    }
    .item {
      width: 50%;
      box-sizing: border-box;
      flex: unset;
    }
  }
}

// Mobile
@media (max-width: 563px) {
  #features {
    .item {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      border-top: 2px solid #F8F8F8;
      &:nth-child(1) {
        border: none;
      }
      .side {
        display: flex;
        .title {

        }
        .description {
          padding-left: 10px;
        }
      }
    }
  }
}
