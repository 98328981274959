
.legal-warning-page {
  height: 100vh;

  #header {
    padding-bottom: 30px;

    .wrap {
      justify-content: space-around;
    }

    .bottom-menu {
      display: none;
    }

    .link-back {
      display: block;
    }
  }

  .legal-warning {
    width: 100%;
    margin-top: 115px;

    .legal-wrap {
      background: aliceblue;
      padding: 30px 0;

      .legal-wrap__content {
        flex: 1;
        overflow: auto;
        max-width: 700px;
        margin: 0 auto;

        h3 {
          text-align: center;
        }
      }

      .legal-wrap__intro {
        margin-bottom: 50px;
      }

      .legal-wrap__table {
        .item-container {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .legal-warning-page {
    .legal-wrap{
      padding: 20px !important;
    }
    .legal-wrap__table {
      text-align: center;
    }
    h4{
      font-size: 18px;
    }

    .item-container {
      & > div {
        flex-direction: column;

        & > div:first-child {
          //width: 100%;
          font-weight: bold;
        }

        & > div {
          width: 100%;
        }
      }
    }
  }
}
