#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 9999;
  padding: 12px;
  box-sizing: border-box;
  .link-back {
    display: none;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    &:hover * {
      color: #01a8e7;
    }
    a, i {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #2C3E50;
      font-weight: 700;
    }
    i {
      margin-right: 5px;
    }
  }
  .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
    & > .dropdown {
      display: none;
    }
  }

  &.scroll {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
  }

  .top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .dropdown {
      display: block;
    }
  }
  .bottom-menu {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    & > div, & > a {
      padding: 0 12px;
      text-decoration: none;
      color: #2C3E50;
      font-weight: 700;
    }
  }
  img{
    width: 250px;
  }

  .menu-burger {
    display: none;
    .icon-burguer {
      cursor: pointer;
      font-size: 30px;
    }
    .aside-menu-wrapper{
      &.show{
        .aside-menu{
          opacity:1;
          pointer-events: auto;
        }
        .shadow{
          opacity:1;
          pointer-events: auto;
        }
      }
    }
    .shadow {
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity:0;
      pointer-events: none;
      transition: 0.5s;
    }
    .aside-menu {
      position: fixed;
      top: 0;
      right: 0;
      padding: 80px 40px 20px;
      color: white;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 300px;
      transition: 0.5s;
      opacity:0;
      pointer-events: none;
      & > div, & > a {
        padding: 10px;
        display: block;
        padding: 10px;
        text-decoration: none;
        color: white;
        font-weight: 600;
        &:hover {
          opacity: 0.5;
        }
      }
      .icon-close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        margin: 10px;
      }
      .dropdown {
        margin: 20px 0;
        .list {
          background: transparent;
          border: 1px solid white;
        }
      }
    }
  }

}

// When it is bigger
@media (min-width: 1330px) {
  #header {
    .wrap{
      flex-direction: row;
      & > .dropdown {
        display: block;
      }
    }
    .top-menu{
      position: relative;
      bottom:7px;
    }

  }
  .top-menu {
    & > .dropdown {
      display: none !important;
    }
  }
}

// When it is small
@media (max-width: 1329px) {
  .top-menu {
   width: 100%;
   max-width: 750px;
   padding-bottom: 20px !important;
  }
}

@media (max-width: 990px) {
  #header {
    .wrap{
      flex-direction: row;
    }
  }
  .menu-burger {
    display: block !important;
    position: relative;
  }
  .top-menu .dropdown {
    display: none !important;
  }
  .bottom-menu {
    display: none !important;
  }
}
