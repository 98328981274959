.meeting-doctors {
  --primary: #01A8E7;
  --secondary: #0177A3;
  --primaryGrafient: #02D3F5 ;
  --secondaryGrafient: #01A8E7 ;
  --tertiaryGrafient: #02D3F5 ;
  --quaternaryGrafient: #01A8E7 ;
  --icons: #2ecc71;
  --tags: #66afe9;
}
