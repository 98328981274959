
#clients {
  .carousel {
    overflow: visible;
    button {
      margin-top: 50px;
    }
  }
  .slider-wrapper{
    display: flex;
    align-items: center;
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
    }
    .swiper-wrapper{
      margin-bottom: 32px;

    }
    [class*='swiper-button-']{
      display: none;
    }
    .clients-slider{
      width: 100%;
    }
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    //max-width: 900px;

    & > div {
      width: 180px;
      height: 180px;
      box-sizing: border-box;
      margin: 0 25px;
      //border: 15px solid transparent;
      filter: saturate(0.1);
      opacity: 0.5;
      transition: 0.5s all;
      &:hover{
        filter: saturate(1);
        opacity: 1;
      }
    }
  }
}



@media (max-width: 900px) {
  #clients {
    h2 {
      font-size: 25px;
    }
    .carousel-custom {
      display: flex;
      width: 80%;
    }
    .grid { display: none !important; }
  }
}
// Desktop and Tablet
@media (min-width: 901px) {
  #clients {
    .carousel-custom { display: none; }
    .grid { display: flex; }
    .slider-wrapper{
      display: none !important;
    }
  }
}

// Large Desktop
@media (min-width: 1200px) {
  #clients {
    .carousel-custom { display: none; }
    .grid {
      display: flex;
      //max-width: 1200px;
    }
  }
}
