.offer {
  padding: 70px 50px;
  align-items: center;
  &>div{
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
  }
  .title {
    text-align: left;
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 15px;
    color: #2C3E50;
  }
  .subtitle {
    position: relative;
    font-size: 18px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &:before {
      content: '';
      width: 200px;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .image {
    position: absolute;
    top: 50%;
    left: -30px;
    height: 600px;
    min-width: 300px;
    &[data-aos^=fade][data-aos^=fade].aos-animate{
      transform: translateY(-50%);
    }
  }
  .text {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    padding: 50px;
    box-sizing: border-box;
    margin: 0 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: row;
    gap: 30px;
  }
  .video{
    border-radius: 10px;
    height: auto;
    width: 45%;
    border: 1.5px solid #c4c4c4;
    cursor: pointer;
  }
  .icon-check {
    font-size: 26px;
    margin-right: 15px;
  }
  .item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
}

// Mobile
@media (max-width: 900px) {
  .offer {
    padding: 20px;
    &>div {
      .title {
        font-size: 25px !important;
      }
      .image {
        display: none;
      }
      .video{
        width: 100%;
      }
      .text {
        flex-flow: column;
        padding: 30px;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
