
.carousel-custom {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    .left {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
    .right {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
    .content {
        flex: 1;
        display: flex;
        justify-content: center;
    }
}

.dots-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    &>div{
        background-color: #F8F8F8;
        width: 16px;
        height: 16px;
        margin: 0 5px;
        border-radius: 50%;
        border: 4px solid #F8F8F8;
    }
}
