.location {
  padding: 72px 0;
  .wrap{
    width: 100%;
    margin: 0 auto;
  }
  h2 {
    text-align: center;
    color: #2C3E50;
  }
  .title{
    margin: 20px 0;
  }
  img{
    width: 100%;
  }
  //.location-map {
  //  height: 500px;
  //  margin: 20px auto;
  //}
}

// Mobile

@media (max-width: 900px) {
  .location {
    padding: 20px;
    h2 {
      font-size: 25px;
    }
    .location-map {
      width: auto;
      height: calc(100vw * 0.5);
    }
  }
}
@media (min-width: 701px) and (max-width: 1399px) {
  .location {
    .wrap {
      width: 70%;
    }
  }
}
