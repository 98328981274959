@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Raleway,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2C3E50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  position: relative;
  font-size: 36px;
  font-weight: 800;
  padding-bottom: 25px;
  color: #2C3E50;
}

h2.underline:before {
  content: '';
  width: 200px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}


@font-face {
  font-family: 'icomoon';
  src: url("./fonts/icomoon.eot?jsanr1");
  src: url("./fonts/icomoon.eot?jsanr1#iefix") format("embedded-opentype"),
       url("./fonts/icomoon.ttf?jsanr1") format("truetype"),
       url("./fonts/icomoon.woff?jsanr1") format("woff"),
       url("./fonts/icomoon.svg?jsanr1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-left:before, .testimonial .testimonial-list .slick-arrow.slick-prev:before {
  content: "\e900"; }

.icon-arrow-right:before, .testimonial .testimonial-list .slick-arrow.slick-next:before {
  content: "\e901"; }

.icon-burguer:before {
  content: "\e902"; }

.icon-calendar:before {
  content: "\e903"; }

.icon-chat:before {
  content: "\e904"; }

.icon-check:before {
  content: "\e905"; }

.icon-close:before {
  content: "\e906"; }

.icon-doctors:before {
  content: "\e907"; }

.icon-email:before {
  content: "\e908"; }

.icon-leaders:before {
  content: "\e909"; }

.icon-pioners:before {
  content: "\e90a"; }

.icon-play:before {
  content: "\e90b"; }

.icon-time:before {
  content: "\e90c"; }

.icon-world:before {
  content: "\e90d"; }
