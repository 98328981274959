
.dropdown {
    position: relative;
    width: 150px;
    .trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
        // border: 1px solid grey;
        padding: 6px;
        border-radius: 4px;
        & > div {
            display: inline-block;
            padding-left: 10px;
        }
        .chevron {
            width: 25px;
            height: 25px;
            background-size: 13px;
        }
    }
    .list {
        position: absolute;
        background: white;
        width: 99%;
        border: 1px solid grey;
        border-radius: 4px;
        margin-top: 3px;
        .item {        
            display: flex;
            align-items: center;
            padding: 6px;
            cursor: pointer;
            .text {
                padding-left: 10px;
            }
        }
    }
}