
#footer {
  padding: 20px;
  background: white;
  .wrap{
    max-width: 1330px;
    width: 100%;
    margin: 0 auto;
  }
  .links {
    display: flex;
    align-items: center;

    .external-pages {
      display: flex;
      align-items: center;
      a:nth-child(1) {
        white-space: nowrap;
      }
      a {
        padding: 10px;
        color: #BDC3C7;
        cursor: pointer;
        text-decoration: none;
      }
      .linkedin {
        display: inline-block;
        width: 80px;
      }
    }
    .images {
      flex: 1;
    }
    .links-map {
      flex: 1;
      text-align: right;
      a {
        display: block;
        padding: 5px;
        color: #BDC3C7;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
  .copyright {
    text-align: center;
    margin: 40px;
  }
}
//small mobile
@media (max-width: 390px) {
  #footer{
    .links{
      .images{
        width: 100%;
        &>a{
          width: 100%;
        }
      }
      .external-pages{
        flex-direction: column;
        padding: 0;
        width: auto;
      }
    }

  }
}
@media (max-width: 844px) {
  .footer {
    .images {
      flex-direction: column;
      & > a > div {
        padding: 10px;
      }
    }
    .links-map {
      flex-direction: column;
      a:nth-child(2) {
        border: none !important;
      }
    }
  }
}


// Mobile
@media (max-width: 1060px) and (max-width: 1060px) {
  #footer {
    .links {
      & > div{
        padding: 12px;
      }
      flex-direction: column;
      .images {
        display: flex;
      }
      .links-map {
        display: flex;
        a {
          padding: 10px;
          &:nth-child(2) {
            border-left: 1px solid lightgrey;
            border-right: 1px solid lightgrey;
          }
        }
      }
    }
  }
}

// Mobile
@media (max-width: 844px) {
  #footer {
    .images {
      flex-direction: column;
      & > a > div {
        padding: 10px;
      }
    }
    .links-map {
      flex-direction: column;
      a:nth-child(2) {
        border: none !important;
      }
    }
  }
}
