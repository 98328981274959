
.intro {
  position: relative;
  font-family: "Raleway", sans-serif;
  margin-top: 64px;
  height: 700px;
  .wrap{
    margin: 0 auto;
    width: 100%;
    max-width: 1330px;
  }
  .text {
    position: absolute;
    top: 0;
    padding: 220px 0%;
    width: 550px;
    .main-text {
      font-size: 64px;
      margin-bottom: 30px;
      font-weight: 300;
      color: #2C3E50;
      span{
        &:last-child{
          font-weight: 800;
        }
      }
    }
    .secondary-text {
      font-size: 22px;
    }
  }
}


// Mobile
@media (max-width: 599px) {
  .intro {
    //padding: 20px;
    .intro-parallax{
      background-position: 67% center;
      opacity: 0.7;
    }
    .text {
      width: auto;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 20px;
    }
  }
}

@media (min-width: 600px) and  (max-width: 768px) {
  .intro {
    height: 525px;
    .text {
      padding: 102px 60px;
      width: 420px;
    }
  }

}
@media (min-width: 768px) {

  .intro-parallax{
    background-attachment: fixed !important;
  }

}


@media (max-width: 990px) {
  .intro {
    //padding: 20px;
    .text {
      //width: auto;
      //padding: 120px 50px;
      .main-text {
        font-size: 51px;

      }
    }
  }
}
@media (min-width: 769px) and  (max-width: 1400px) {
  .intro {
    .text{
      padding: 220px 10%;
    }
  }
}

