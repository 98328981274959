.contact{
  color: white;
  font-family: "Raleway", sans-serif;
  .wrap{
    display: flex;
    align-items: center;
    padding: 110px 0px;
  }
  &>*{
    width: 50%;
  }
  form{
    flex: 1;
    label{
      font-size: 18px;
      display: block;
    }
    input {
      position: relative;
    }
    &>*{
      width: 100%;
      margin: 9px 0 ;
    }
    .error-validation {
      font-size: 12px;
      color: red;
      position: relative;
      top: -8px;
    }
  }
  i{
    font-size: 62px;
  }
  .title{
    font-size:48px;
    font-weight: bold;
    &:after{
      content: '';
      width: 200px;
      background: white;
      height: 3px;
      display: block;
      margin-top: 30px;
    }
  }
  input, textarea{
    color: #60739b;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
    &::placeholder{
      color: #999999;
    }
  }
  textarea{
    resize: none;
  }
  button{
    font-weight: 500;
    outline: none;
    border: none;
    font-size: 18px;
    padding: 15px 40px;
    color: white;
    width: auto;
    display: block;
    margin: 0 0 0 auto;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
//small mobile
@media (max-width: 390px) {
.recaptacha{
  transform: scale(0.84);
  transform-origin: 0 0;
}
}
@media (min-width: 992px) {
  .contact{
    input{
      width: 100%;
    }
    .side{
      width: 30%;
      padding: 0 10%;
    }
  }
}
@media (max-width: 1399px) {
  .contact{
    .wrap{
      padding: 110px 55px;
    }
  }
}
@media (max-width: 992px) {
  .contact{
    .wrap{
      display: block;
      padding: 60px 25px;
      width: 100%;
      box-sizing: border-box;
    }
    form {
      padding-top: 30px;
    }
    &> * {
      width: 100%;
    }
    .title {
      font-size:36px;
    }
    i {
      font-size: 50px;
    }
  }
}


@media (max-width: 800px) {
  .contact{
    form {
      padding-top: 30px;
    }
    .title {
      font-size:36px;
    }
    i {
      font-size: 50px;
    }
  }
}
