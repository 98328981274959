
#testimony {
  margin: 0 auto;
  overflow: hidden;
  padding-top: 120px;

  .custom-slider {
    position: relative;
    [class*='swiper-button-'] {
      display: none;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 60px;
  }

  .carousel-custom {
    max-width: 900px;
  }

  .avatar {
    position: absolute;
    top: -40px;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .item {
    position: relative;
    padding: 60px 30px 30px 50px;
    box-shadow: 0 2px 7px 0 #00000021;
    width: 376px;
    height: 280px;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 10px;
  }

  .name {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .text {
    font-size: 18px;
    text-align: left;
  }

  .swiper-container {
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    position: relative;
    padding: 60px 30px 30px 50px;
    width: 376px;
    height: 280px;
    box-sizing: border-box;
    margin: 47px 0px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 95%;
      height: 95%;
      top: 50%;
      left: 50%;
      box-shadow: 0 2px 7px 0 #00000021;
      transform: translate(-50%, -50%);
      border-radius: 6px;
    }

    ///* Center slide text vertically */
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: -webkit-flex;
    //display: flex;
    //-webkit-box-pack: center;
    //-ms-flex-pack: center;
    //-webkit-justify-content: center;
    //justify-content: center;
    //-webkit-box-align: center;
    //-ms-flex-align: center;
    //-webkit-align-items: center;
    //align-items: center;
  }
}

// Mobile
@media (max-width: 790px) {
  #testimony {
    padding: 20px;
    width: 80% !important;
  }
}

// Mobile
@media (max-width: 1100px) {
  #testimony {
    padding: 20px;
    width: 50% !important;
    h2 {
      font-size: 25px;
    }
    .wrap {
      width: 100%;
    }
    .text {
      font-size: 15px !important;
    }
  }
}

// Mobile
@media (max-width: 1956px) {
  #testimony {
    padding: 20px;
    width: 80%;

    h2 {
      font-size: 25px;
    }

    .wrap {
      width: 100%;
    }
  }
}

// Mobile
@media (max-width: 1240px) {
  #testimony {
    padding: 20px;
    width: 70%;

    h2 {
      font-size: 25px;
    }

    .wrap {
      width: 100%;
    }
  }
}

// Small Mobile
@media (max-width: 550px) {
  #testimony {
    width: 70% !important;
    .swiper-slide {
      padding: 50px 30px 60px 30px;
      height: auto;
      .name {
        margin-bottom: 10px;
      }
      .text {
        font-size: 14px !important;
      }
    }
    .carousel-custom {
      margin: 0 auto;

      .item {
        width: 100%;
        height: auto;
        padding: 58px 20px 30px 20px;

        .name {
          margin-bottom: 10px;
        }
      }
    }
  }
}
